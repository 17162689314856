;globalThis["_sentryRewritesTunnelPath"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"01b6d945e507d074da33776606fd62a11d60510e"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "/fem/01b6d945e507d074da33776606fd62a11d60510e";import * as Sentry from '@sentry/nextjs'
import {getEnvironment} from 'sentry.utils'
import {env} from '~/config/env.mjs'
import {isProd} from '~/config/nodeEnv'

Sentry.init({
  dsn: env.NEXT_PUBLIC_SENTRY_DSN,
  debug: false,
  enabled: isProd,
  environment: getEnvironment(),
})
